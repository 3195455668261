import React from 'react';
import styled from "styled-components";

export const Seal = () => {
    return (
        <Wrap>
            <svg width="192" height="192" viewBox="0 0 192 192">
                <path d="M96 1.97938C44.0738 1.97938 1.97938 44.0738 1.97938 96C1.97938 147.926 44.0738 190.021 96 190.021C147.926 190.021 190.021 147.926 190.021 96C190.021 44.0738 147.926 1.97938 96 1.97938ZM0 96C0 42.9807 42.9807 0 96 0C149.019 0 192 42.9807 192 96C192 149.019 149.019 192 96 192C42.9807 192 0 149.019 0 96Z" />
                <path d="M118.733 16.088L121.438 16.937C122.993 17.4209 123.731 18.3095 123.295 19.6821L123.279 19.7218C123.041 20.4755 122.517 20.9594 121.581 20.9515C122.541 21.499 122.85 22.2051 122.533 23.2127L122.517 23.2523C122.073 24.6566 120.922 25.0771 119.264 24.5535L116.353 23.6411L118.733 16.088ZM119.415 23.5142C120.359 23.8077 120.922 23.5221 121.168 22.7366L121.184 22.697C121.43 21.9115 121.121 21.3879 120.081 21.0626L118.812 20.6659L118.05 23.0857L119.415 23.5142ZM120.272 20.0709C121.2 20.3644 121.668 20.1343 121.914 19.3647L121.93 19.3251C122.152 18.6031 121.843 18.1667 120.938 17.889L119.796 17.532L119.114 19.7138L120.272 20.0709Z" />
                <path d="M128.262 19.4203L130.039 20.1819L129.317 28.5046L127.913 27.9016L128.103 25.8467L125.318 24.6567L123.978 26.2196L122.7 25.6722L128.262 19.4203ZM126.056 23.7998L128.206 24.7201L128.587 20.8801L126.056 23.7998Z" />
                <path d="M131.173 26.7036L132.419 27.3938C132.117 28.1396 132.078 28.8775 133.252 29.5281C134.021 29.9485 134.799 29.8216 135.156 29.1869C135.513 28.5522 135.323 28.1238 134.299 27.3224C132.76 26.18 132.252 25.2438 132.974 23.9426C133.601 22.8081 135.045 22.4907 136.489 23.2841C137.98 24.1092 138.504 25.2834 137.901 26.6877L136.719 26.0371C136.997 25.2358 136.751 24.6963 135.949 24.26C135.164 23.8236 134.545 23.9823 134.252 24.5138C133.942 25.0771 134.021 25.4897 135.124 26.3307C136.632 27.4573 137.219 28.3856 136.449 29.774C135.783 30.98 134.244 31.3608 132.712 30.5119C130.824 29.4487 130.546 28.0682 131.173 26.7036Z" />
                <path d="M141.186 26.1482L145.28 28.933L144.621 29.893L141.741 27.9333L140.519 29.7264L142.828 31.2973L142.201 32.2176L139.892 30.6467L138.591 32.5588L141.622 34.6216L140.964 35.5816L136.711 32.6857L141.186 26.1482Z" />
                <path d="M144.344 33.1856L144.399 33.1142C145.93 31.2814 148.255 31.0593 149.945 32.4715C151.341 33.6378 151.841 35.2404 150.778 36.7796L149.628 35.8196C150.175 34.9072 150.072 34.1138 149.199 33.376C148.128 32.4795 146.732 32.7333 145.597 34.09L145.542 34.1614C144.399 35.534 144.375 36.8907 145.502 37.8269C146.343 38.533 147.295 38.6044 148.136 37.8428L149.239 38.7631C147.834 40.0801 146.184 39.9532 144.732 38.7393C142.828 37.1604 142.773 35.058 144.344 33.1856Z" />
                <path d="M155.498 37.7555L156.839 39.1439L152.967 46.5383L151.904 45.4355L152.872 43.6186L150.77 41.4447L148.921 42.3571L147.961 41.3654L155.498 37.7555ZM151.777 40.9369L153.404 42.6189L155.236 39.2232L151.777 40.9369Z" />
                <path d="M160.156 42.7221L161.354 44.293L158.315 49.2279L163.853 47.5697L165.035 49.1247L158.736 53.9327L157.839 52.7584L162.6 49.1327L156.411 50.886L156.126 50.5052L159.426 44.9674L154.666 48.5932L153.856 47.5379L160.156 42.7221Z" />
                <path d="M166.59 51.5605L167.899 53.7502C168.812 55.2735 168.733 56.6778 167.304 57.5267L167.265 57.5505C165.829 58.4074 164.567 57.7568 163.702 56.297L163.131 55.3449L160.545 56.892L159.791 55.6305L166.59 51.5605ZM164.591 55.7257C165.091 56.5667 165.765 56.7651 166.519 56.3208L166.559 56.297C167.368 55.813 167.4 55.1466 166.916 54.3453L166.36 53.4249L164.036 54.8133L164.591 55.7257Z" />
                <path d="M171.93 61.2874L172.937 63.6358C173.635 65.2702 173.374 66.6507 171.842 67.3013L171.803 67.3171C170.264 67.9756 169.105 67.1585 168.439 65.5955L168.002 64.5799L165.233 65.77L164.654 64.4133L171.93 61.2874ZM169.399 65.1512C169.788 66.0477 170.43 66.3413 171.231 65.9922L171.271 65.9763C172.136 65.6034 172.255 64.9528 171.882 64.088L171.462 63.0963L168.978 64.1595L169.399 65.1512Z" />
                <path d="M174.873 68.4517L175.683 70.9905C176.214 72.6487 175.905 74.0292 174.358 74.5211L174.318 74.5369C173.136 74.9098 172.295 74.3703 171.755 73.5055L169.169 76.6712L168.685 75.1479L171.113 72.1965L170.827 71.2999L167.772 72.2679L167.328 70.8636L174.873 68.4517ZM172.184 72.1012C172.485 73.0454 173.041 73.4103 173.842 73.1565L173.882 73.1406C174.746 72.8629 174.873 72.2282 174.588 71.3317L174.231 70.1971L171.819 70.9667L172.184 72.1012Z" />
                <path d="M173.255 76.7029L173.342 76.687C175.611 76.2269 177.626 77.5201 178.094 79.8289C178.562 82.1456 177.237 84.0735 174.96 84.5337L174.873 84.5495C172.604 85.0097 170.581 83.7958 170.105 81.4394C169.621 79.0513 171.009 77.1472 173.255 76.7029ZM174.556 83.0421L174.643 83.0262C176.238 82.7009 177.245 81.5743 176.936 80.051C176.627 78.5277 175.325 77.8533 173.651 78.1866L173.564 78.2024C171.866 78.5436 170.978 79.7971 171.263 81.2094C171.565 82.6851 172.866 83.3832 174.556 83.0421Z" />
                <path d="M179.07 86.1998L179.276 88.5958C179.514 91.3489 178.055 92.8166 175.754 93.015L175.659 93.0229C173.358 93.2213 171.612 92.0232 171.374 89.2464L171.168 86.8662L179.07 86.1998ZM172.525 89.1115C172.675 90.8808 173.762 91.6424 175.508 91.4917L175.595 91.4837C177.301 91.3409 178.261 90.4761 178.102 88.6196L178.031 87.7707L172.445 88.2467L172.525 89.1115Z" />
                <path d="M174.762 94.9509L179.673 95.054L179.641 96.5376L174.841 96.4345C173.389 96.4028 172.779 96.855 172.755 98.0292C172.731 99.1717 173.231 99.7588 174.873 99.7985L179.578 99.9016L179.546 101.385L174.722 101.282C172.652 101.235 171.533 100.092 171.581 97.9975C171.612 96.014 172.723 94.9033 174.762 94.9509Z" />
                <path d="M175.405 103.25L175.492 103.258C177.856 103.567 179.292 105.408 179.007 107.597C178.769 109.398 177.69 110.676 175.817 110.612L176.016 109.129C177.079 109.097 177.698 108.589 177.848 107.455C178.031 106.066 177.071 105.027 175.309 104.797L175.222 104.789C173.453 104.559 172.295 105.265 172.104 106.717C171.961 107.804 172.414 108.645 173.5 108.946L173.31 110.374C171.454 109.898 170.668 108.431 170.914 106.558C171.231 104.099 172.985 102.924 175.405 103.25Z" />
                <path d="M176.476 114.436L176.976 112.286L178.11 112.548L176.77 118.292L175.635 118.031L176.135 115.88L169.55 114.341L169.891 112.897L176.476 114.436Z" />
                <path d="M175.127 123.632L174.254 125.877C173.254 128.456 171.302 129.13 169.152 128.297L169.057 128.257C166.907 127.424 165.86 125.584 166.867 122.989L167.732 120.768L175.127 123.632ZM167.97 123.37C167.328 125.02 167.97 126.187 169.605 126.821L169.684 126.853C171.279 127.472 172.516 127.123 173.191 125.385L173.5 124.592L168.28 122.569L167.97 123.37Z" />
                <path d="M171.747 131.867L169.51 136.286L168.471 135.763L170.042 132.653L168.106 131.669L166.844 134.16L165.852 133.66L167.114 131.169L165.043 130.122L163.385 133.398L162.346 132.875L164.662 128.289L171.747 131.867Z" />
                <path d="M163.917 135.136L163.163 136.334C162.433 135.993 161.703 135.914 160.989 137.048C160.521 137.786 160.608 138.571 161.227 138.96C161.846 139.349 162.274 139.19 163.139 138.214C164.361 136.747 165.329 136.294 166.582 137.08C167.677 137.77 167.915 139.23 167.035 140.626C166.13 142.07 164.924 142.522 163.56 141.848L164.282 140.706C165.059 141.023 165.614 140.809 166.098 140.039C166.574 139.277 166.455 138.651 165.94 138.333C165.392 137.992 164.98 138.048 164.083 139.103C162.877 140.547 161.909 141.078 160.568 140.23C159.402 139.5 159.117 137.945 160.045 136.461C161.195 134.628 162.592 134.43 163.917 135.136Z" />
                <path d="M163.901 145.117L163.021 146.315L156.634 141.618L157.514 140.42L163.901 145.117Z" />
                <path d="M158.585 145.625L158.649 145.68C160.426 147.235 160.616 149.576 159.093 151.321C157.974 152.598 156.403 153.225 154.832 152.138L155.8 151.028C156.713 151.583 157.522 151.353 158.196 150.575C159.22 149.409 158.934 147.973 157.625 146.831L157.562 146.775C156.284 145.656 154.888 145.49 153.793 146.743C152.817 147.862 153.095 148.909 153.888 149.671L155.197 148.171L156.031 148.901L153.761 151.496L153.206 151.012C151.556 149.568 151.453 147.656 152.928 145.974C154.626 144.054 156.824 144.085 158.585 145.625Z" />
                <path d="M154.888 155.716L153.587 156.907L147.256 154.931L151.127 159.16L150.136 160.064L144.788 154.209L145.915 153.178L152.651 155.256L148.549 150.774L149.54 149.869L154.888 155.716Z" />
                <path d="M147.93 161.826L143.955 164.785L143.256 163.849L146.049 161.77L144.748 160.025L142.511 161.691L141.852 160.802L144.09 159.136L142.709 157.279L139.766 159.469L139.067 158.533L143.193 155.463L147.93 161.826Z" />
                <path d="M141.9 166.181L139.631 167.562C138.148 168.466 136.735 168.498 135.894 167.11L135.871 167.07C135.228 166.007 135.561 165.063 136.267 164.333L132.586 162.563L133.951 161.73L137.386 163.389L138.187 162.897L136.521 160.159L137.783 159.39L141.9 166.181ZM137.719 164.428C136.878 164.944 136.648 165.57 137.092 166.292L137.116 166.332C137.592 167.11 138.235 167.078 139.036 166.594L140.052 165.975L138.735 163.817L137.719 164.428Z" />
                <path d="M129.824 172.687L128.047 173.441L122.533 167.173L123.945 166.57L125.294 168.125L128.079 166.935L127.881 164.888L129.158 164.341L129.824 172.687ZM128.182 168.07L126.032 168.99L128.547 171.925L128.182 168.07Z" />
                <path d="M123.565 175.17L121.129 175.932C119.44 176.464 118.091 176.059 117.599 174.472L117.583 174.433C117.083 172.838 118.019 171.767 119.638 171.259L120.693 170.926L119.797 168.046L121.201 167.609L123.565 175.17ZM119.987 172.259C119.059 172.552 118.702 173.163 118.963 173.996L118.979 174.036C119.257 174.932 119.9 175.123 120.796 174.837L121.82 174.52L121.01 171.941L119.987 172.259Z" />
                <path d="M116.154 177.352L113.663 177.884C111.926 178.257 110.624 177.725 110.275 176.099L110.267 176.059C109.918 174.425 110.942 173.441 112.608 173.092L113.695 172.862L113.068 169.91L114.512 169.601L116.154 177.352ZM112.862 174.123C111.91 174.329 111.497 174.901 111.68 175.757L111.688 175.797C111.886 176.717 112.505 176.963 113.425 176.765L114.48 176.543L113.917 173.893L112.862 174.123Z" />
                <path d="M108.594 178.82L107.126 178.994L106.317 172.29L102.961 172.695L102.818 171.529L107.642 170.942L108.594 178.82Z" />
                <path d="M101.62 179.534L100.136 179.613L99.7158 171.695L101.199 171.616L101.62 179.534Z" />
                <path d="M97.8354 175.75V175.837C97.772 178.217 96.09 179.843 93.8843 179.78C92.0675 179.732 90.6791 178.788 90.5521 176.916L92.0516 176.955C92.1865 178.011 92.7657 178.574 93.9082 178.598C95.3045 178.637 96.2407 177.574 96.2883 175.797V175.71C96.3359 173.925 95.5108 172.846 94.043 172.806C92.9482 172.774 92.1548 173.314 91.9723 174.425L90.5283 174.385C90.8139 172.481 92.1865 171.553 94.0748 171.6C96.5581 171.687 97.8989 173.306 97.8354 175.75Z" />
                <path d="M86.2679 179.098L84.3558 178.844L82.8008 170.64L84.3161 170.838L84.689 172.87L87.6881 173.266L88.5608 171.402L89.9333 171.584L86.2679 179.098ZM87.2041 174.29L84.8874 173.98L85.5618 177.781L87.2041 174.29Z" />
                <path d="M79.104 176.725L81.2541 177.201L81.0002 178.336L75.2402 177.051L75.4941 175.916L77.6442 176.392L79.112 169.791L80.5639 170.116L79.104 176.725Z" />
                <path d="M73.224 176.448L71.7959 176.027L74.0571 168.427L75.4772 168.847L73.224 176.448Z" />
                <path d="M70.948 171.64L70.9162 171.719C70.0673 173.877 67.8855 174.861 65.7037 174.004C63.506 173.139 62.6015 170.981 63.4584 168.815L63.4901 168.736C64.3391 166.578 66.4574 165.547 68.6948 166.427C70.948 167.316 71.781 169.514 70.948 171.64ZM64.9103 169.283L64.8786 169.363C64.2835 170.878 64.6802 172.338 66.1242 172.909C67.5681 173.48 68.8455 172.751 69.4723 171.164L69.504 171.084C70.1387 169.474 69.5675 168.046 68.2267 167.514C66.8382 166.967 65.545 167.681 64.9103 169.283Z" />
                <path d="M59.7762 171.338L58.2212 170.505L57.9991 163.873L55.2857 168.926L54.1035 168.292L57.8562 161.31L59.205 162.032L59.4748 169.077L62.3548 163.722L63.5369 164.356L59.7762 171.338Z" />
                <path d="M51.9147 161.461C51.4625 162.095 50.5977 162.262 49.9392 161.794C49.2727 161.318 49.1537 160.445 49.6059 159.818C50.0582 159.176 50.915 159.009 51.5815 159.485C52.24 159.945 52.3669 160.818 51.9147 161.461Z" />
                <path d="M48.05 154.487L47.2883 155.352C47.1534 155.09 47.0027 154.836 46.6536 154.526C46.1141 154.05 45.5111 154.027 44.8685 154.756L41.2427 158.882L40.124 157.898L43.7736 153.749C44.9082 152.456 46.1696 152.519 47.2725 153.487C47.7644 153.923 47.9468 154.225 48.05 154.487Z" />
                <path d="M41.759 152.765L38.2046 156.153L37.1811 155.074L40.6561 151.757C41.7114 150.758 41.8224 150.004 41.0052 149.155C40.2198 148.33 39.4502 148.267 38.2601 149.393L34.8565 152.638L33.833 151.559L37.3239 148.235C38.8234 146.807 40.4261 146.823 41.87 148.346C43.2426 149.79 43.2426 151.361 41.759 152.765Z" />
                <path d="M36.6817 146.497L35.8089 145.379C36.3802 144.807 36.7055 144.149 35.8883 143.086C35.3488 142.395 34.5871 142.197 34.0079 142.649C33.4288 143.102 33.4367 143.554 34.0556 144.704C35.0156 146.362 35.1108 147.426 33.9365 148.33C32.9131 149.123 31.4612 148.846 30.4456 147.537C29.3984 146.188 29.3904 144.903 30.4932 143.855L31.3184 144.926C30.7471 145.553 30.763 146.148 31.3184 146.862C31.8658 147.576 32.4926 147.672 32.9765 147.299C33.4843 146.902 33.5795 146.497 32.8893 145.291C31.9531 143.665 31.7785 142.578 33.0321 141.602C34.119 140.761 35.682 141.023 36.7531 142.403C38.086 144.117 37.7924 145.498 36.6817 146.497Z" />
                <path d="M27.5171 141.269L28.7072 143.125L27.7313 143.752L24.5498 138.786L25.5336 138.159L26.7237 140.015L32.4202 136.366L33.2215 137.619L27.5171 141.269Z" />
                <path d="M23.5344 136.945L22.8203 135.644L29.7783 131.843L30.4924 133.145L23.5344 136.945Z" />
                <path d="M21.4961 133.184L20.7821 131.574L24.6617 126.187L19.4254 128.519L18.8779 127.297L26.1216 124.068L26.7483 125.465L22.6465 131.201L28.2002 128.725L28.7477 129.947L21.4961 133.184Z" />
                <path d="M16.799 121.418L16.0849 118.856C15.6168 117.182 15.9739 115.817 17.5289 115.381L17.5686 115.365C18.7666 115.032 19.5838 115.611 20.0915 116.491L22.7891 113.421L23.2175 114.96L20.6866 117.816L20.9405 118.721L24.0268 117.864L24.4235 119.284L16.799 121.418ZM19.6235 117.864C19.3616 116.912 18.8142 116.523 18.0049 116.753L17.9653 116.769C17.0925 117.015 16.9418 117.642 17.1957 118.546L17.513 119.689L19.9487 119.006L19.6235 117.864Z" />
                <path d="M14.696 113.373L14.4579 111.921L17.7822 111.366L17.2031 107.859L13.8788 108.415L13.6328 106.947L21.4477 105.646L21.6936 107.113L18.3693 107.669L18.9485 111.176L22.2728 110.62L22.5108 112.072L14.696 113.373Z" />
                <path d="M17.3223 104.313H17.235C14.9183 104.424 13.1173 102.853 13.0062 100.505C12.8872 98.1482 14.4819 96.4345 16.8066 96.3234H16.8938C19.2105 96.2123 21.0274 97.7118 21.1464 100.108C21.2654 102.528 19.6072 104.202 17.3223 104.313ZM16.9811 97.8467H16.8938C15.2674 97.9261 14.1011 98.894 14.1805 100.449C14.2598 102.004 15.4419 102.861 17.1477 102.782L17.235 102.774C18.9646 102.686 20.0277 101.584 19.9563 100.14C19.8849 98.648 18.6948 97.7594 16.9811 97.8467Z" />
                <path d="M13.1094 92.4675L13.2204 90.5396L21.2813 88.3895L21.194 89.9128L19.2026 90.4365L19.0281 93.4593L20.9481 94.1892L20.8687 95.5697L13.1094 92.4675ZM17.9728 93.0547L18.1077 90.7221L14.3709 91.6742L17.9728 93.0547Z" />
                <path d="M13.6328 85.7158L14.0216 83.3436C14.4738 80.6223 16.2431 79.5512 18.5201 79.9241L18.6153 79.94C20.8923 80.3128 22.2966 81.9075 21.8364 84.6527L21.4477 87.009L13.6328 85.7158ZM20.694 84.5099C20.9796 82.7565 20.1148 81.7489 18.3852 81.4633L18.2979 81.4474C16.616 81.1697 15.4655 81.7647 15.1641 83.6054L15.0292 84.4464L20.5591 85.3588L20.694 84.5099Z" />
                <path d="M15.2666 76.9489L16.5519 72.1647L17.6785 72.4662L16.774 75.8302L18.8686 76.3935L19.5985 73.696L20.6696 73.9816L19.9397 76.6791L22.177 77.2821L23.1291 73.7436L24.2557 74.0451L22.9149 79.0117L15.2666 76.9489Z" />
                <path d="M22.2654 72.1727L22.7573 70.8398C23.5427 71.0223 24.2726 70.9588 24.7407 69.6973C25.0422 68.8801 24.8042 68.1264 24.1219 67.8725C23.4396 67.6186 23.0508 67.8646 22.4002 68.9991C21.5037 70.689 20.6469 71.3237 19.2584 70.808C18.0445 70.3558 17.513 68.9753 18.0922 67.4282C18.6872 65.8256 19.7662 65.1432 21.2498 65.5241L20.7738 66.7935C19.9407 66.6428 19.4488 66.9601 19.1315 67.817C18.8221 68.658 19.0601 69.2451 19.6313 69.4593C20.2343 69.6814 20.631 69.5466 21.2895 68.3247C22.1781 66.6666 23.0112 65.9525 24.4948 66.4999C25.7801 66.976 26.3831 68.4437 25.7722 70.0861C25.0264 72.1092 23.7014 72.5852 22.2654 72.1727Z" />
                <path d="M25.5501 61.7713C24.8598 61.4143 24.5663 60.5813 24.9312 59.8593C25.3041 59.1294 26.153 58.8913 26.8433 59.2404C27.5415 59.5975 27.835 60.4226 27.4621 61.1525C27.0892 61.8745 26.2483 62.1284 25.5501 61.7713Z" />
                <path d="M28.7232 54.734L28.6518 54.6864C26.7476 53.3694 26.2716 51.0289 27.6045 49.093C28.9453 47.1492 31.2462 46.7604 33.1662 48.0775L33.2376 48.1251C35.1417 49.4421 35.6812 51.735 34.3086 53.7105C32.9361 55.7178 30.6035 56.0351 28.7232 54.734ZM32.3807 49.3945L32.3093 49.3469C30.9685 48.4186 29.4531 48.4821 28.5724 49.7594C27.6918 51.0368 28.1123 52.4411 29.5166 53.409L29.588 53.4646C31.0081 54.4484 32.5314 54.2183 33.3486 53.0282C34.2055 51.7905 33.7929 50.3704 32.3807 49.3945Z" />
                <path d="M34.2694 46.8953L34.2059 46.8398C32.4128 45.2689 32.2383 42.9363 33.6981 41.2781C34.8961 39.9135 36.5067 39.4533 38.0221 40.5403L37.0383 41.6669C36.1338 41.0956 35.3404 41.1829 34.5867 42.0398C33.6664 43.095 33.8885 44.4914 35.2214 45.6576L35.2849 45.7132C36.6257 46.8874 37.9824 46.9429 38.9424 45.8401C39.6644 45.015 39.7596 44.0629 39.0218 43.214L39.9738 42.1271C41.2591 43.5552 41.0925 45.2133 39.8469 46.6256C38.2125 48.5059 36.11 48.498 34.2694 46.8953Z" />
                <path d="M39.5846 36.9938L37.9741 38.5013L37.1807 37.6523L41.4808 33.614L42.2742 34.4629L40.6636 35.9703L45.297 40.9052L44.2101 41.9208L39.5846 36.9938Z" />
                <path d="M45.004 35.8196L44.9485 35.7482C43.5362 33.9076 43.8139 31.5353 45.6784 30.0993C47.5508 28.6633 49.8675 29.0044 51.2797 30.853L51.3352 30.9244C52.7475 32.7651 52.5412 35.1135 50.6371 36.5733C48.7091 38.049 46.3924 37.6286 45.004 35.8196ZM50.1293 31.8606L50.0738 31.7892C49.082 30.496 47.6222 30.0914 46.3924 31.0355C45.1627 31.9796 45.123 33.4474 46.1624 34.8041L46.2179 34.8755C47.2731 36.2481 48.7885 36.494 49.9309 35.6213C51.121 34.7009 51.1766 33.2252 50.1293 31.8606Z" />
                <path d="M50.9932 26.4338L53.4209 24.966C54.8173 24.125 55.9598 24.1568 56.7056 25.3865L56.7294 25.4262C57.134 26.1006 57.1578 26.8146 56.531 27.5128C57.5704 27.1558 58.3161 27.3938 58.8556 28.2983L58.8794 28.3379C59.6411 29.5994 59.1889 30.7419 57.7052 31.6464L55.095 33.2252L50.9932 26.4338ZM54.9918 27.9174C55.8249 27.4097 55.9677 26.9178 55.5472 26.2196L55.5234 26.1799C55.1347 25.5373 54.5951 25.4817 53.7859 25.9736L52.7624 26.5925L53.9446 28.5442L54.9918 27.9174ZM57.0071 30.8371C57.848 30.3293 58.0147 29.7105 57.5862 29.0123L57.5624 28.9726C57.134 28.2665 56.539 28.1555 55.6028 28.7188L54.4603 29.409L55.7773 31.5829L57.0071 30.8371Z" />
                <path d="M58.0234 22.3558L62.4902 20.1978L62.998 21.2451L59.8641 22.7605L60.8082 24.7201L63.3233 23.5062L63.8072 24.5059L61.2922 25.7198L62.2998 27.8064L65.6082 26.2038L66.116 27.251L61.4826 29.4963L58.0234 22.3558Z" />
                <path d="M64.7666 19.2219L67.2658 18.2937C68.9002 17.6907 70.2886 17.9287 70.8519 19.4441L70.8678 19.4837C71.2962 20.65 70.7884 21.5148 69.9554 22.094L73.24 24.5297L71.7405 25.0851L68.678 22.8001L67.7973 23.1254L68.916 26.1323L67.5355 26.648L64.7666 19.2219ZM68.5431 21.737C69.4714 21.3958 69.8126 20.8166 69.519 20.0233L69.5031 19.9836C69.1858 19.1347 68.5511 19.0394 67.6625 19.3647L66.5517 19.7773L67.4324 22.1495L68.5431 21.737Z" />
                <path d="M76.7551 23.2365L78.9845 19.4996C79.5478 18.5555 79.8335 17.778 79.6748 17.0401C79.5161 16.3181 79.0004 15.9532 78.2546 16.1198C77.485 16.2943 77.0963 16.8418 77.2153 17.9684L75.8983 18.2619C75.6206 16.5482 76.414 15.3502 78.0483 14.9852C79.651 14.6282 80.8093 15.3343 81.1108 16.699C81.333 17.7066 80.9363 18.7142 80.2143 19.809L78.8655 21.8719L82.1581 21.134L82.404 22.2448L76.8107 23.4983L76.7551 23.2365Z" />
                <path d="M83.1658 18.2778L83.1182 17.8811C82.8168 15.493 83.9592 13.8666 85.9348 13.6127C87.9262 13.3667 89.4098 14.652 89.7034 17.0243L89.751 17.421C90.0445 19.7852 88.9973 21.4434 86.9424 21.7053C84.943 21.9512 83.4594 20.6421 83.1658 18.2778ZM88.2911 17.6034L88.2435 17.2147C88.0373 15.5406 87.2677 14.6124 86.0934 14.7552C84.9034 14.9059 84.3718 15.9294 84.5939 17.6828L84.6416 18.0795C84.8637 19.8646 85.6571 20.6818 86.8313 20.531C88.0452 20.3803 88.5133 19.3886 88.2911 17.6034Z" />
                <path d="M91.1787 20.8642L94.0904 17.6193C94.8204 16.8021 95.2567 16.088 95.2409 15.3343C95.2171 14.5885 94.7886 14.1363 94.019 14.1601C93.2336 14.1839 92.7417 14.652 92.6465 15.7707L91.2898 15.8104C91.3453 14.0808 92.3609 13.0494 94.0349 13.0018C95.6772 12.9542 96.6769 13.8745 96.7166 15.2709C96.7483 16.3023 96.1612 17.2147 95.2409 18.1509L93.5271 19.9201L96.899 19.8249L96.9308 20.9674L91.1946 21.1261L91.1787 20.8642Z" />
                <path d="M98.4065 17.2146L98.4303 16.8179C98.5969 14.414 100.017 13.0335 102.016 13.1683C104.016 13.3032 105.23 14.8503 105.063 17.2305L105.039 17.6272C104.881 20.0074 103.532 21.4355 101.469 21.3006C99.4538 21.1657 98.2478 19.5948 98.4065 17.2146ZM103.564 17.532L103.587 17.1432C103.698 15.4612 103.127 14.3981 101.945 14.3188C100.747 14.2394 100.033 15.1439 99.914 16.9052L99.8902 17.3019C99.7712 19.095 100.39 20.047 101.58 20.1264C102.794 20.2136 103.445 19.325 103.564 17.532Z" />
                <path d="M109.808 18.7697C109.951 18.0081 110.672 17.4924 111.466 17.6431C112.275 17.7938 112.743 18.5396 112.592 19.3013C112.442 20.0709 111.736 20.5866 110.934 20.4279C110.141 20.2771 109.657 19.5393 109.808 18.7697Z" />
            </svg>

        </Wrap>
    );
}

const Wrap = styled.div`
    width: 192px;
    height: 192px;
    position: absolute;
    right: -1em;
    top: calc(-8em + 40px);
    animation: rotation 60s infinite linear;
    svg {
        fill: #FFFFFF;
    }
    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
    @media (min-width: 768px) {
        right: 2em;
    }
`;
